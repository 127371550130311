<script lang="ts">import Wrap from '$components/slot/Wrap.svelte';
import { slides } from '$config/mainvisual';
let center = slides.length;
function increment() {
    center += 1;
    if (center === slides.length * 2 - 1) {
        center = slides.length - 1;
    }
}
function decrement() {
    center -= 1;
    if (center === 1) {
        center = slides.length * 2 - 2;
    }
}
</script>

<svelte:head>
  <script nomodule>
    document.addEventListener('DOMContentLoaded', function() {
      var LENGTH = 3;
      var center = LENGTH;
      var slides = document.getElementById('slides');
      var prev = document.getElementById('prev');
      var next = document.getElementById('next');

      setPosition(center);

      prev.addEventListener('click', function() {
        console.log('load');
        center += 1;

        if(center === LENGTH * 2 - 1) {
          center = LENGTH - 1;
        }

        setPosition(center);
      });

      next.addEventListener('click', function() {
        center -= 1;

        if(center === 1) {
          center = LENGTH * 2 - 2;
        }

        setPosition(center);
      });

      function setPosition(position) {
        var margin = 100 * position;
        slides.style.left = '-' + margin + '%';
      }
    });
  </script>
</svelte:head>

<div class="mainvisual">
  <Wrap fullOnMobile>
    <button id="prev" class="prev" on:click={decrement}>
      <img src="/images/arrow-prev.png" alt="＜">
    </button>
    <button id="next" class="next" on:click={increment}>
      <img src="/images/arrow-next.png" alt="＞">
    </button>
    <ul id="slides" style="width: {slides.length * 2 * 100}%; left: -{100 * center}%">
      {#each slides as s, index}
        <li class="slide--{index}">
          <img
            sizes="100vw"
            src={s.src}
            srcset="{s.sp} 768w, {s.src}"
            alt={s.name}
            loading="lazy"
          >
        </li>
      {/each}
      {#each slides as s, index}
        <li class="slide--{index}">
          <img
            sizes="100vw"
            src={s.src}
            srcset="{s.sp} 768w, {s.src}"
            alt={s.name}
            loading="lazy"
          >
        </li>
      {/each}
    </ul>
  </Wrap>
</div>

<style type="text/scss">.mainvisual {
  overflow-x: hidden;
}

button {
  position: absolute;
  z-index: 2;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
button img {
  width: 50px;
}
button.prev {
  left: 0.5rem;
}
button.next {
  right: 0.5rem;
}
button:focus {
  outline: none;
}

ul {
  position: relative;
  z-index: 1;
  display: flex;
  overflow: visible;
  list-style: none;
  transition-duration: 0.3s;
}

li {
  position: relatvie;
  width: 100%;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 768px) {
  button {
    top: 50%;
  }
  button img {
    width: 40px;
  }
}</style>
