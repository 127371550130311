<script lang="ts">export let alt;
export let src;
export let srcset;
export let href;
export let width = '100%';
</script>

<a {href} style="width: {width}">
  <img {src} {srcset} {alt}>
</a>

<style type="text/scss">a {
  position: relative;
  display: block;
  transition-duration: 0.3s;
}
a:hover {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

img {
  display: block;
  width: 100%;
  height: auto;
}</style>
