<script lang="ts">import Nav from '$components/navigation/Nav.svelte';
import MainVisual from '$components/index/MainVisual.svelte';
import Wrap from '$components/slot/Wrap.svelte';
import Section from '$components/slot/Section.svelte';
import Inner from '$components/slot/Inner.svelte';
import Columns from '$components/column/Columns.svelte';
import Column from '$components/column/Column.svelte';
import ImageLink from '$components/button/ImageLink.svelte';
</script>

<svelte:head>
  <title>協和自動車有限会社 | 群馬県前橋市で自動車車検・修理なら協和自動車</title>
  <meta name="description" content="私たち協和自動車は1964年に創業した会社です。以来、モータリゼーションの波をとらえ、群馬県が全国トップクラスの自動車王国になる一端を担ってまいりました。その間、高所作業車で国内トップシェアを持つアイチコポーレーションのサービス工場に指定。今日では、軽自動車から普通自動車、さ らには大型・特殊車両まで、幅広くサービスを提供する自動車整備会社へと成長しています。">
  <meta property="og:url" content="https://kyouwaji.com/">
  <meta property="og:title" content="協和自動車有限会社 | 群馬県前橋市で自動車車検・修理なら協和自動車">
  <meta property="og:type" content="website">
  <meta property="og:description" content="私たち協和自動車は1964年に創業した会社です。以来、モータリゼーションの波をとらえ、群馬県が全国トップクラスの自動車王国になる一端を担ってまいりました。その間、高所作業車で国内トップシェアを持つアイチコポーレーションのサービス工場に指定。今日では、軽自動車から普通自動車、さ らには大型・特殊車両まで、幅広くサービスを提供する自動車整備会社へと成長しています。">
</svelte:head>

<Nav segment="home"></Nav>

<MainVisual></MainVisual>

<Section kind="top">
  <Wrap>
    <Inner>
      <ImageLink
        href="recruit"
        src="images/top-04.jpg"
        srcset="images/top-04-sp.jpg 768w, images/top-04.jpg"
        loading="lazy"
      ></ImageLink>
    </Inner>
  </Wrap>
</Section>

<Section kind="top">
  <Wrap>
    <Inner>
      <h2>
        <img
          src="images/index-heading-1.png"
          srcset="images/index-heading-1-sp.png 768w, images/index-heading-1.png"
          alt="軽から普通、大型・特殊まで、あらゆる整備ニーズに誠実に対応いたします。"
        >
      </h2>
    </Inner>
  </Wrap>
</Section>

<Section kind="top">
  <Wrap>
    <Inner>
      <Columns space="wide">
        <Column columns="2" space="wide">
          <figure>
            <img src="images/top-05.jpg" alt="1964年の創業から地元密着で自動車王国・群馬を支えてきました">
          </figure>
        </Column>
        <Column columns="2" space="wide">
          <div class="content">
            <h3>1964年の創業から地元密着で自動車王国・群馬を支えてきました</h3>
            <p>世帯あたりの自動車保有台数が全国トップクラスの群馬県。1964年の創業から自動車整備のプロ集団として、そうした自動車王国の一端を担い、軽から普通、大型・特殊まで、あらゆる整備ニーズに対応してきました。</p>
          </div>
        </Column>
      </Columns>
    </Inner>
  </Wrap>
</Section>

<Section>
  <Wrap>
    <Inner>
      <Columns space="wide">
        <Column columns="2" space="wide">
          <figure>
            <img src="images/top-06.jpg" alt="">
          </figure>
        </Column>
        <Column columns="2" space="wide">
          <div class="content">
            <h3>高所作業車の指定サービス工場として卓越した整備技術を誇っています。</h3>
            <p>高所作業車の製造で全国トップのシェアを持つアイチコポーレーション。その指定サービス工場になっていることから、特殊車両の整備においてもプロフェッショナルの高い技術力を発揮しています。</p>
          </div>
        </Column>
      </Columns>
    </Inner>
  </Wrap>
</Section>

<style type="text/scss">.content h3 {
  padding-bottom: 0.75rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: #727272;
  border-bottom: 1px solid #00ada9;
}
.content p {
  margin-top: 0.75rem;
  line-height: 2;
}</style>
